import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import staffPhoto from '../images/staff-photo.webp';
import styles from '../styles/Home.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavbar } from '../context/NavbarContext';

export default function Home() {
  const { closeNav } = useNavbar();
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [centerIndex, setCenterIndex] = useState(1); // Initialize to the second card for 3 visible slides
  
  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstMessage(prev => !prev);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const carouselItems = [
    {
      title: "Healthcare Staff",
      description: `Our dedicated team ensures you receive the best permanent hires to support your healthcare organization.`,
      list: [
        "Nursing (RNs, LPNs, NPs)",
        "Medical Technicians",
        "Healthcare Administration",
        "Medical Billing and Coding",
        "Allied Health Professionals"
      ]
    },
    // {
    //   title: "Connecting You with Top Talent",
    //   description: `We focus solely on direct placements, ensuring you find the perfect permanent hires for your organization. Our streamlined process guarantees you the best fit, so you can focus on driving your business forward.`,
    //   highlight: "Utilize our extensive database of over 200 million candidates."
    // },
    {
      title: "Construction Staff",
      description: `From building out your team to securing essential permanent hires, our experts connect you with experienced professionals in the construction industry.`,
      list: [
        "Project Management",
        "Civil Engineering",
        "Construction Supervision",
        "Skilled Trades (Electricians, Plumbers, Carpenters)",
        "Safety and Compliance"
      ]
    },
    {
      title: "Technology Staff",
      description: `Our relationship-driven approach ensures you receive the best talent for your projects.`,
      list: [
        "Full Stack Development",
        "Enterprise Data Management",
        "UX/UI",
        "Project Management and Business Analysis",
        "Infrastructure",
        "Cybersecurity",
        "Networking and Engineering",
        "Agile Transformation",
        "BI/AI"
      ]
    },
    // {
    //   title: "Business Staff",
    //   description: `From building out a team to securing key permanent hires, our experts connect you with experienced Business professionals.`,
    //   list: [
    //     "Financial Planning and Analysis",
    //     "Data and Analytics",
    //     "Regulatory and Compliance",
    //     "Transactional and Operational Accounting"
    //   ]
    // },
    {
      title: "Drivers Staff",
      description: `Finding the right drivers for your transportation needs is crucial.`,
      list: [
        "Commercial Truck Driving (CDL-A, CDL-B)",
        "Delivery and Courier Services",
        "Bus Driving",
        "Freight and Logistics Coordination",
        "Ride-Share and Private Chauffeur Services"
      ]
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    centerMode: true,
    centerPadding: '0',
    beforeChange: (current, next) => setCenterIndex(next),
    className: styles.sliderWrapper,
    dotsClass: `slick-dots ${styles.dots}`,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0',
          adaptiveHeight: true,
          arrows: true,
          swipe: true,
          swipeToSlide: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0',
          adaptiveHeight: true,
          arrows: true,
          swipe: true,
          swipeToSlide: true,
          dots: true
        }
      }
    ]
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={staffPhoto} alt="Staff" className={styles.image} />
        </div>
        <div className={styles["text-overlay"]}>
          <h1 className={styles.heading}>Bridging the gap between talent and opportunity</h1>
          <div className={styles.buttonsContainer}>
            <Link to="/find-job" className={styles.button} onClick={closeNav}>FIND A JOB</Link>
            <Link to="/find-talent" className={styles.button} onClick={closeNav}>FIND TALENT</Link>
          </div>
        </div>
      </div>
      <div className={styles.aboutSummarySection}>
        <div className={styles.aboutSummaryContent}>
          <h2>Our Unique Benefits</h2>
          <p>At Apex Staff Global, we are your trusted partner in global talent solutions, delivering exceptional value through our comprehensive approach to staffing.</p>
          <div className={styles.benefitsGrid}>
            <div className={styles.highlight}>
              <h3>Cost</h3>
              <p>We understand budget constraints, so we offer flexible payment options to ensure you get the best value for your investment.</p>
            </div>
            <div className={styles.highlight}>
              <h3>Control Over Hiring</h3>
              <p>Our approach is highly collaborative, keeping you involved at every step to maintain full control and transparency over the process.</p>
            </div>
            <div className={styles.highlight}>
              <h3>Value</h3>
              <p>To demonstrate the high quality of our talent pool, we provide detailed candidate profiles and conduct thorough vetting.</p>
            </div>
            <div className={styles.highlight}>
              <h3>Confidentiality</h3>
              <p>Protecting your sensitive information is paramount to us. We strictly adhere to confidentiality agreements.</p>
            </div>
            <div className={styles.highlight}>
              <h3>Quality</h3>
              <p>Our rigorous screening, reference and multiple interview processes ensure that we only present top-notch candidates to you.</p>
            </div>
            <div className={styles.highlight}>
              <h3>Existing Networks</h3>
              <p>While your internal networks are valuable, our extensive database offers access to a broader range of high-caliber candidates.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactButtonSection}>
        <Link to="/contact-us" className={styles.contactButton} onClick={closeNav}>Get in Touch</Link>
      </div>
      <div className={styles.carouselContainer}>
        <div className={styles.carouselHeader}>
          <h2 className={styles.carouselTitle}>Connecting You with Top Talent</h2>
          <p className={styles.carouselSubtitle}>We focus solely on direct placements, ensuring you find the perfect permanent hires for your organization. Our streamlined process guarantees you the best fit, so you can focus on driving your business forward.</p>
          <p className={styles.carouselHeaderHighlight}>Utilize our extensive database of over 200 million candidates.</p>
        </div>
        <div className={styles.carousel}>
          <Slider {...settings}>
            {carouselItems.map((item, index) => (
              <div key={index} className={`${styles.card} ${index === centerIndex ? styles.centerCard : ''}`}>
                <h3 className={styles.cardTitle}>{item.title}</h3>
                <p className={styles.cardDescription}>{item.description}</p>
                {item.highlight && <p className={styles.cardHighlight}>{item.highlight}</p>}
                {item.list && (
                  <ul className={styles.cardList}>
                    {item.list.map((listItem, idx) => (
                      <li key={idx}>{listItem}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className={styles.contactSection}>
        <h2 className={styles.contactHeading}>Let's Connect</h2>
        <p className={`${styles.contactText} ${styles.fadeText}`}>
          {isFirstMessage 
            ? "Ready to take the next step in your career journey? We're here to help."
            : "Looking to fill your next position? Let's find your ideal candidate."}
        </p>
        <Link to="/contact-us" className={styles.contactButton}>Get in Touch</Link>
      </div>
    </>
  );
}